import "firebase/compat/firestore";
import app from "./fire.js";

var db = app.firestore();

export const presaleAddress = async () => {
    var presale1 = [];

    await db.collection("post-presale")
        .get()
        .then((querySnapshot) => {
            querySnapshot.forEach((res) => {
                var data = res.data();

                for (var each in data) {
                    presale1.push(data[each]);
                }
            });
        });
    
    return [presale1];
}