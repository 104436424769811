// import Modal from "../components/Modal";
import email from "../assets/img/email.svg";

import { useState } from "react";

function Footer() {
  const [showModal, setShowModal] = useState(false);

  return (
    <footer className="px-10 py-3">
      <div className="flex w-full justify-between">
        <h2 className=" text-xl text-white">
          Copyright © 2022 The Last Kolas Stronghold. All rights reserved.
        </h2>
        <button
          onClick={() => setShowModal(!showModal)}
          className="social-icons join__discord   flex items-center space-x-2 rounded-full border-2  border-green  px-8 py-3 text-base font-bold text-green"
        >
          <img src={email} alt="" />
          <p> Subscribe</p>
        </button>
      </div>
      {/* {showModal && (
        <Modal showModal={showModal} onClose={() => setShowModal(false)} />
      )} */}
    </footer>
  );
}

export default Footer;
