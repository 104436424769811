import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";

// import gif from "./assets/img/hero.gif";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(
    `Click on mint to Buy your NFT Token.`
  );
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 4,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Pending`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      // .mintPublic(mintAmount)
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(`successful`);
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 50) {
      newMintAmount = 50;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);
  



  return (
    <div className="gif">
    <div className="mint__page h-[100vh]  w-full px-8 py-44 md:px-40">
      <div
        className="w-full"
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/mint.png" : null}
      >
        <div className="mx-auto w-full py-32">
          {/* <div className="mint__box flex h-full rounded-md bg-black py-10 shadow-lg"> */}
          <div className="mint__box flex h-full rounded-md  py-1 shadow-lg">
            <div className="flex w-full items-center justify-center   py-6 md:space-x-10 md:px-10 md:py-8">
              <div className="">
                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                  <></>
                ) : (
                  <>
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <div className="flex w-full flex-col items-center justify-center">
                        {/* <h3 className="font-primary text-center text-sm font-semibold text-white md:text-2xl md:tracking-wider">
                          Connect to the {CONFIG.NETWORK.NAME} Network
                        </h3> */}

                        

                         <h3 className="font-primary text-right text-sm font-semibold text-white md:text-2xl md:tracking-wider">
                         When 100% Sold
                         
                        </h3>
                         <h3 className="font-primary text-left text-sm font-semibold text-white md:text-xl md:tracking-wider">
                          <p>- Giving away The Leader #1 to one lucky holder.</p>
                         <p>- The Last Koala Stronghold vol.1 will give you mint pass to volume 2.</p> 
                          <p>- Hidden Prices inside Koalas will be unlocked.</p>
                        </h3>
                       
                        <div class="w-full mt-7 bg-blue-200 rounded-full dark:bg-blue-700">
    <div class="bg-green text-xs font-medium text-blue-100 text-center p-2.5 leading-none rounded-full" style={{width : "50%"}}>  43% Sold</div>
  </div>
                        <button
                          className="bg-purple font-primary mt-16 rounded-md bg-green px-10 py-4 font-bold tracking-wider text-black"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          CONNECT
                        </button>
                        <br></br>
                        <h3 className="font-primary text-center text-sm font-semibold text-white md:text-2xl md:tracking-wider">
                          Connect to the {CONFIG.NETWORK.NAME} Network
                          
                        </h3>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <h2 class="font-Opensans mt-5 text-center font-semibold text-white">
                              {blockchain.errorMsg}
                            </h2>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex w-full flex-col items-center justify-center space-y-4 md:space-y-8 ">
                        <h2 className="font-primary text-base font-semibold text-white md:text-2xl">
                          {feedback}
                        </h2>
{/* 
                             <div className="flex items-center space-x-12 bg-white px-8 py-2 md:space-x-24">
                              <button
                                className="h-16  w-16 rounded-full bg-green text-5xl font-semibold text-white"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  decrementMintAmount();
                                }}
                              >
                                -
                              </button>

                              <h2 className="text-2xl font-bold text-green md:text-3xl">
                                {mintAmount}
                              </h2>

                              <button
                                className="h-16 w-16 rounded-full bg-green text-5xl font-semibold text-white"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  incrementMintAmount();
                                }}
                              >
                                +
                              </button>
                            </div> */}

                            {/* <button
                              className="font-primary mint__shadow flex items-center space-x-2 rounded-lg bg-green px-10 py-4 text-xl text-white"
                              disabled={claimingNft ? 1 : 0}
                              onClick={(e) => {
                                e.preventDefault();
                                claimNFTs();
                                getData();
                              }}
                            >
                              <p className="text-lg font-bold md:text-3xl">
                                {" "}
                                {claimingNft ? "Pending" : "Mint"}
                              </p>
                            </button>  */}
                            <div className="flex space-x-4 md:space-x-10">
                              <div className="item-center flex md:space-x-3">
                                <button
                                  className="h-16 w-16  rounded-lg border border-green  text-2xl font-semibold text-white"
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    decrementMintAmount();
                                  }}
                                >
                                  -
                                </button>

                                <div className="flex h-16 w-16 flex-col items-center justify-center rounded-lg">
                                  <h2 className="text-2xl font-semibold text-white md:text-xl">
                                    {mintAmount}
                                  </h2>
                                </div>

                                <button
                                  className="h-16 w-16 rounded-lg border border-green  text-2xl font-semibold text-white"
                                  disabled={claimingNft ? 1 : 0}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    incrementMintAmount();
                                  }}
                                >
                                  +
                                </button>
                              </div>
                              <button
                                className="h-16 w-full rounded-lg bg-green px-5 text-xl font-bold text-white"
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                  e.preventDefault();
                                  claimNFTs();
                                  getData();
                                }}
                              >
                                {claimingNft ? "Pending" : "Mint"}
                              </button>
                            </div>

                            <div className="flex justify-center">
                              <div class="font-primary flex items-center tracking-wider text-white md:mt-6 md:space-x-20 md:text-xl md:font-semibold">
                                <span>
                                  Phase 2 : {data.totalSupply}{" "}/ 1000 Minted
                                </span>
                                
                                {/* <span> Max 1 NFT</span> */}
                              </div>
                              
                            </div>
                       
                      
                      </div>
                     
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
  
}

export default Mint;
