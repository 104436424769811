import firebase from "firebase/compat/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBEGohPcUHG-wAATOhFhH6NrH4zcDJ1sus",
  authDomain: "wl-test-181b2.firebaseapp.com",
  projectId: "wl-test-181b2",
  storageBucket: "wl-test-181b2.appspot.com",
  messagingSenderId: "912846387167",
  appId: "1:912846387167:web:b799741ac6f0a0fd5c766a"
};

const app = firebase.initializeApp(firebaseConfig);

export default app;
