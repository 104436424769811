import vol1 from "../assets/img/vol1.png";
import Test from "./Test";

function Roadmap() {
  return (
    <section className="py-10 px-8 md:mt-20 md:px-0">
      {/* <h2 className="text-center text-2xl font-semibold uppercase text-white">
        Roadmap Section
      </h2> */}
      <div className="relative">
        <img className="w-full" src={vol1} alt="" />
        <div className="mx-auto w-full max-w-4xl">
          <div className="absolute top-6 md:top-12 ">
            <h2 className="title text-title font-semibold md:text-3xl">
              VOL.01
            </h2>
            <p className="text-2xl">The Never Ending Fire</p>
          </div>
          <div className="space-y-6 text-justify text-base md:space-y-10 md:text-xl">
            <p>
              Koalas. You might think they’re just cute, cuddly, semi-stoned
              marsupials with nothing going on in their cuddly little brains.
              Maybe you think they’re not very bright and make great animal
              plush toys, but nothing more. Those ideas are understandable but
              couldn’t be more wrong. In reality, the Koalas have developed a
              highly sophisticated prefrontal cortex and have been secretly
              plotting an extreme voyage for some time now. They’re in trouble,
              being chased from their habitats by a horrific atomic fire that is
              consuming everything they’ve ever known. Their plight is extreme,
              but their Supreme leader has one last ploy to help them escape
              from their dwindling habitats.
            </p>
            <p>
              They’re doing everything they can to preserve their race despite
              their dire circumstances. Their mission is to save themselves from
              the endless radioactive fire that is ravaging their home, the
              climate, and other species! But their task does not end with
              self-preservation: their ultimate goal is to save the forest and
              the other species that call it home as well.
            </p>
            <p>
              They’ve managed to build a machine that keeps their city in the
              eucalyptus trees safe for now via a transparent shield, which has
              been working for now. But their forcefield shield is showing signs
              of weakness and clearly won’t sustain their defense forever. The
              Crazy Koala Scientist has inspected the shield and determined that
              their days are numbered. The Koala Shaman, their spiritual leader,
              has foreseen its fall and issued a prophecy of warning: failure to
              act will result in certain and irreversible death for the entire
              forest and everything in it. That's why they’ve sent out soldiers
              to the front lines of the inferno in order to put out the fire in
              small chunks - before the defense system gives up entirely and
              lets the fire ravage them, erasing the Koalas from the face of the
              earth!
            </p>
            <p>
              The Suprême Leader of the Koalas is doing everything in his power
              to stop the fire and multiply his people through a sacred aspect
              of their culture: art. He has trained an army of battle-ready
              koalas led by his most loyal generals to preserve and flourish his
              race, but their task is not easy, and they need your help.
            </p>
            <p>
              Together, they have a plan to save the forests from the fire and
              ultimately re-establish what was lost by replanting the forest and
              restoring their habitat. From there, their race can multiply, and
              they can take power in the forest! The Supreme Leader’s plans
              don’t stop with an ethical mission to save the forest and his own
              species though; with the help of the propaganda minister, he has
              hatched an ambitious plan to become the Supreme Leader over ALL
              the species in the forest! With time and with the help of his
              power, he’s assembling an army of top-trained soldiers who will
              save his kind and later be used to take over the world! There is a
              dark side to every ambition, but for now he’s the forest’s only
              hope.
            </p>
          </div>
        </div>
        {/* <div className="py-20">
          <Test />
        </div> */}
        <div className="flex w-full flex-col items-center">
          <a
            href="/worldmap"
            className="rounded-xl bg-green px-5 mt-10 py-4 text-base font-semibold uppercase shadow-md"
          >
            Back to Worldmap
          </a>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
