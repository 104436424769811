import React, { useState } from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";

import twitter from "../assets/img/twitter.svg";
import discord from "../assets/img/discord.svg";
import instagram from "../assets/img/instagram.svg";
import tiktok from "../assets/img/tiktok.svg";
import facebook from "../assets/img/facebook.svg";

import Modal from "../components/Modal";
import email from "../assets/img/email.svg";

function Header() {
  const [showLinks, setShowLinks] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  return (
    <header className="sticky top-0 left-0 z-50 w-full bg-black">
      {/* <button
        onClick={() => setShowModal(!showModal)}
        className="subscribe__btn fixed right-20  top-4  flex items-center  space-x-2  rounded-full border-2 border-green bg-black px-2 py-1 text-base font-bold text-green  md:right-14"
      >
        <img src={email} alt="" />
        <p className="text-sm"> Subscribe</p>
      </button> */}
      <div className="mx-auto w-full px-8 md:max-w-7xl md:py-1">
        <div className="nav-center">
          <div className="nav-header space-x-10">
            <figure>
              <Link to="/">
                <img src={logo} alt="Logo" className="h-8 md:h-10" />
              </Link>
            </figure>
{/* <ul class="absolute top-6 right-20 "> <li class="md:hidden"> <a href="https://thelastkoalastronghold.xyz/mint">Mint Live</a></li></ul> */}

            <button onClick={() => setShowHeader(!showHeader)}>
              <AiOutlineMenu className="absolute top-6 right-10 cursor-pointer text-2xl text-white md:top-14 md:hidden" />
            </button>
          </div>
          <div
            className={`${
              showLinks ? "links-container show-container" : "show-container"
            }`}
          >
            <ul className="links text-sm  uppercase text-white md:space-x-6 md:text-xl">
              <li className="links">
                <Link
                  onClick={() => setShowLinks(true)}
                  className="link"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="links">
                <Link
                  onClick={() => setShowLinks(true)}
                  className="link"
                  to="/about"
                >
                  About us
                </Link>
              </li>
              <li className="links">
                <Link
                  onClick={() => setShowLinks(true)}
                  className="link"
                  to="/worldmap"
                >
                  Worldmap
                </Link>
              </li>
              {/* <li className="links">
                <Link
                  onClick={() => setShowLinks(true)}
                  className="link"
                  to="/roadmap"
                >
                  Roadmap
                </Link>
              </li> */}

              <li className="links">
                <Link
                  onClick={() => setShowLinks(true)}
                  className="link"
                  to="/faq"
                >
                  FAQs
                </Link>
              </li>
              {/* <li className="links">
                <Link
                  onClick={() => setShowLinks(true)}
                  className="link"
                  to="/mint"
                >
                  Mint NFT
                </Link>
              </li> */}

              {/* <li className="block md:hidden">
                <ul className="flex space-x-3">
                  <a href="" target="_blank">
                    <img className="h-5" src={twitter} alt="Twitter" />
                  </a>
                  <a href="" target="_blank">
                    <img className="h-5" src={facebook} alt="Discord" />
                  </a>
                  <a href="" target="_blank">
                    <img className="h-5" src={tiktok} alt="Discord" />
                  </a>
                  <a href="" target="_blank">
                    <img className="h-5" src={discord} alt="Discord" />
                  </a>
                  <a href="" target="_blank">
                    <img className="h-5" src={instagram} alt="Instagram" />
                  </a>
                </ul>
              </li> */}
            </ul>
          </div>

          <div className="social-icons flex items-center space-x-5">
            <div className="flex items-center space-x-4">
              <a href="https://twitter.com/KoalaStronghold" target="_blank">
                <img className="h-6" src={twitter} alt="Twitter" />
              </a>
              {/* <a href="" target="_blank">
                <img className="h-6" src={facebook} alt="Discord" />
              </a>
              <a href="" target="_blank">
                <img className="h-6" src={tiktok} alt="Discord" />
              </a> */}
              {/* <a href="https://discord.com/invite/R9PchnSTmc" target="_blank">
                <img className="h-6" src={discord} alt="Discord" />
              </a>
              <a
                href="https://www.instagram.com/koalastronghold/"
                target="_blank"
              >
                <img className="h-6" src={instagram} alt="Instagram" />
              </a> */}
            </div>
          </div>
        </div>
      </div>

      {showHeader && (
        <div className="header__container absolute top-0 left-0">
          <div className="md:hidden">
            <div className="mt-3 flex w-full items-center justify-between px-8">
              <figure>
                <Link to="/">
                  <img src={logo} alt="Logo" className="h-10 md:h-14" />
                </Link>
              </figure>
              <button
                onClick={() => setShowHeader(false)}
                className="text-close text-white"
              >
                <RiCloseCircleFill />
              </button>
            </div>
            <ul className="title flex flex-col justify-between space-y-8 p-6 text-2xl font-semibold">
              <li className=" p-2 uppercase">
                <Link onClick={() => setShowHeader(false)} to="/">
                  Home
                </Link>
              </li>
              <li className="uppercase">
                <Link
                  onClick={() => setShowHeader(false)}
                  className=""
                  to="/about"
                >
                  About us
                </Link>
              </li>
              <li className="uppercase">
                <Link
                  onClick={() => setShowHeader(false)}
                  className=""
                  to="/worldmap"
                >
                  Worldmap
                </Link>
              </li>
              <li className="uppercase">
                <Link
                  onClick={() => setShowHeader(false)}
                  className=""
                  to="/faq"
                >
                  FAQs
                </Link>
              </li>
              {/* <li className="uppercase">
                <Link
                  onClick={() => setShowHeader(false)}
                  className=""
                  to="/mint"
                >
                  Mint NFT
                </Link>
              </li> */}

              <li className="flex items-center justify-between">
                <p className="text-xl uppercase">Twitter</p>
                <a href="https://twitter.com/KoalaStronghold" target="_blank">
                  <img className="h-7" src={twitter} alt="" />
                </a>
              </li>
              {/* <ul classname="twitter"> <li>Twitter</li></ul> */}
           

              {/* <li className="flex items-center justify-between">
                <p className="text-xl uppercase">Discord</p>
                <a href="https://discord.com/invite/R9PchnSTmc" target="_blank">
                  <img className="h-7" src={discord} alt="" />
                </a>
              </li>
              <li className="flex items-center justify-between">
                <p className="text-xl uppercase">Instagram</p>
                <a
                  href="https://www.instagram.com/koalastronghold/"
                  target="_blank"
                >
                  <img className="h-7" src={instagram} alt="" />
                </a>
              </li> */}
              {/* <li className="flex items-center justify-between">
                    <p className="uppercase">facebook</p>
                    <img src={facebook} alt="" />
                  </li>
                  <li className="flex items-center justify-between">
                    <p className="uppercase">tiktok</p>
                    <img src={tiktok} alt="" />
                  </li> */}
            </ul>
          </div>
        </div>
      )}

      {showModal && (
        <Modal showModal={showModal} onClose={() => setShowModal(false)} />
      )}
    </header>
  );
}

export default Header;
