import React, { useState, useRef } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import faq1 from "../assets/img/faq-1.png";
import faq2 from "../assets/img/faq-2.png";

function Faq() {
  const [clicked, setClicked] = useState(false);
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [selected, setSelected] = useState(null);

  const toggle = () => {
    setClicked(!clicked);
    // setSecond(false);
  };

  const firstState = () => {
    setFirst(!first);
    setSecond(false);
    setThird(false);
    setFourth(false);
    setFifth(false);
  };

  const secondState = () => {
    setFirst(false);
    setSecond(!second);
    setThird(false);
    setFourth(false);
    setFifth(false);
  };
  const thirdState = () => {
    setFirst(false);
    setSecond(false);
    setThird(!third);
    setFourth(false);
    setFifth(false);
  };
  const fourthState = () => {
    setFirst(false);
    setSecond(false);
    setThird(false);
    setFourth(!fourth);
    setFifth(false);
  };
  const fifthState = () => {
    setFirst(false);
    setSecond(false);
    setThird(false);
    setFourth(false);
    setFifth(!fifth);
  };
  return (
    <section className="faq relative mt-20 py-10 md:py-20" id="faq">
      <div className="mx-auto w-full px-8 md:mt-10 md:max-w-5xl">
        <h2 className="title py-5 text-center text-title font-semibold md:text-3xl">
          FAQS
        </h2>
        <div className="z-10 mt-10">
         
          <Accordion
            preExpanded={["a"]}
            allowZeroExpanded
            className="space-y-6"
          >
          
            <AccordionItem
              uuid="a"
              className={`${
                first === true
                  ? "faq__open cursor-pointer  py-3  px-4"
                  : "faq__close  cursor-pointer  py-3 px-4"
              }`}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div
                    className="flex items-center justify-between"
                    onClick={firstState}
                  >
                    <h2 className="text-xl md:text-lg">
                    What Blockchain?
                    </h2>
                    <span className="font-bold ">
                      {first ? (
                        <BiChevronUp className="text-2xl font-bold md:text-4xl" />
                      ) : (
                        <BiChevronDown className="text-2xl font-bold md:text-4xl" />
                      )}
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="py-2  md:max-w-5xl md:py-2 md:text-base">
                 
                  <ul className="list-disc px-8 text-sm md:text-base">
                    <li>
                    Koala's will live at the Ethereum Blockchain 🏠
                    </li>
                  </ul>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

          
            {/* <AccordionItem
              className={`${
                second === true
                  ? "faq__open  cursor-pointer py-3  px-4"
                  : "faq__close  cursor-pointer  py-3 px-4"
              }`}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div
                    className="flex items-center justify-between "
                    onClick={secondState}
                  >
                    <h2 className="md:text-lg">
                    How Many total supply? Wen Minting?
                    </h2>
                    <span className="font-bold">
                      {second ? (
                        <BiChevronUp className="text-2xl font-bold md:text-4xl" />
                      ) : (
                        <BiChevronDown className="text-2xl font-bold md:text-4xl" />
                      )}
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="py-2  text-base md:max-w-5xl  md:py-2 md:text-lg">
                 
                  <ul className="list-disc px-8 text-sm md:text-base">
                    <li>
                    *8,888* divided in to *4 Volumes. We will mint the first Volume consisted of **2,222* Koalas on TBD 🐨
                    </li>
                  </ul>
                </div>
              </AccordionItemPanel>
            </AccordionItem> */}

            {/* <AccordionItem
              className={`${
                third === true
                  ? "faq__open  cursor-pointer py-3  px-4"
                  : "faq__close  cursor-pointer  py-3 px-4"
              }`}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div
                    className="flex items-center justify-between "
                    onClick={thirdState}
                  >
                    <h2 className="text-lg">
                    What is Allow-listed? 📃
                    </h2>
                    <span className="font-bold">
                      {third ? (
                        <BiChevronUp className="text-2xl font-bold md:text-4xl" />
                      ) : (
                        <BiChevronDown className="text-2xl font-bold md:text-4xl" />
                      )}
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="py-2 text-base md:max-w-5xl   md:py-3">
                  <ul className="list-disc px-8 text-sm md:text-base">
                    <li>
                    Allow listed is a role that you can get by registering at https://www.premint.xyz/thelastkoalastronghold/
                    </li>
                    <li>
                    Being allow listed gets you an access to 1 free-mint and 2 mint for 0.022 eth. Maximum of 3 Koalas per wallet
                    </li>
                    <li>
                    Mint price 0.022 ETH
                    </li>
                  </ul>
                </div>
              </AccordionItemPanel>
            </AccordionItem> */}
            {/* <AccordionItem
              className={`${
                fourth === true
                  ? "faq__open  cursor-pointer py-3  px-4"
                  : "faq__close  cursor-pointer  py-3 px-4"
              }`}
            >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div
                    className="flex items-center justify-between "
                    onClick={fourthState}
                  >
                    <h2 className="text-lg">What is whitelisted?🧾</h2>
                    <span className="font-bold">
                      {fourth ? (
                        <BiChevronUp className="text-2xl font-bold md:text-4xl" />
                      ) : (
                        <BiChevronDown className="text-2xl font-bold md:text-4xl" />
                      )}
                    </span>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="py-2 text-base   md:py-3">
                 
                  <ul className="list-disc px-8 text-sm md:text-base">
                    <li>
                    Whitelist is being gained from giveaways and winning activities here on our Discord server
                    </li>
                    <li>
                    Whitelisted members area able to mint the earliest and can mint 1 Koala per wallet
                    </li>
                    <li>
                    Mint price (TBD)
                    </li>
                  </ul>
                </div>
              </AccordionItemPanel>
            </AccordionItem> */}

          </Accordion> 
        </div>
      </div>
      <img
        className="index absolute left-0 top-10 hidden lg:block"
        src={faq1}
        alt="Faq Left"
      />
      <img
        className="index  absolute right-0 bottom-5 hidden lg:block"
        src={faq2}
        alt="Faq Right"
      />
    </section>
  );
}

export default Faq;
