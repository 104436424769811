import React, { useEffect, useState, useRef } from "react";
import app from "./components/fire.js";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { presaleAddress } from "./components/Address";
import { ExportToCsv } from "export-to-csv";

var db = app.firestore();

function Whitelist() {
  const [message, setMessage] = useState(
    `Send the whitelited address for mint approval`
  );
  const [presaleMode, setPresaleMode] = useState("post-presale");
  const [whitelistAddresses, setWhitelistAddesses] = useState([]);
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    headers: ["Name", "Address"], //<-- Won't work with useKeysAsHeaders present!
  };

  const submitAddress = async (event) => {
    event.preventDefault();
    setMessage("Sending data");
    const addresses = event.target.address.value.toLowerCase().split("\n");

    console.log(addresses);

    for (var each in addresses) {
      var address = addresses[each];
      // check address
      if (address.length !== 42) {
        setMessage("Incorrect Address Length: " + address);
        return;
      }

      var found = false;

      await db
        .collection(presaleMode)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((res) => {
            var data = res.data();
            if (data["address"].toString() == address.toString()) {
              found = true;
            }
          });
        });

      if (found === true) {
        setMessage("Already Added : " + address);
        return;
      }

      await db.collection(presaleMode).add({
        address: address,
      });
    }

    setMessage("Successfully Added to " + presaleMode);
  };

  const getAddresses = async () => {
    var addressList = [];

    await db
      .collection("presale")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((res) => {
          var data = res.data();

          addressList.push({
            NAME: data["name"],
            ADDRESS: data["address"],
          });
        });
      });

    setWhitelistAddesses(addressList);
  };

  const downloadData = async () => {
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(whitelistAddresses);
  };

  useEffect(() => {
    getAddresses();
  }, []);

  return (
    <div className="mt-20 flex w-full flex-col items-center">
      <div className="flex flex-col  space-y-16">
        <div className="flex flex-col items-center space-y-5">
          <form
            className="flex flex-col items-center space-y-4"
            onSubmit={submitAddress}
          >
            <label className="text-2xl uppercase"> Enter Address list </label>

            <textarea
              className="outline-none h-16 w-40 rounded-md p-2 text-black md:w-96"
              name="address"
            ></textarea>

            {/* </textarea>
        <input type="textarea"
          name="address"
          /> */}
            <div>
              <input
                className="rounded-md bg-green px-4 py-2 font-semibold"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
          <h2 style={{ color: "white" }}>{message}</h2>
        </div>

        <div>
          <div className="space-y-4 text-center text-white">
            <h1> Address from database </h1>

            <button
              className="rounded-md bg-green px-4 py-3 font-semibold"
              onClick={downloadData}
            >
              DOWNLOAD DATA
            </button>

            {whitelistAddresses.map((each) => (
              <h1>
                {each["NAME"] === "" ? "No username" : each["NAME"]} ==>{" "}
                {each["ADDRESS"]}{" "}
              </h1>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whitelist;
