import React, { useEffect, useState, useRef } from "react";
import app from "./components/fire.js";
import "firebase/compat/firestore";
import { presaleAddress } from "./components/Address";

import MerkleTree from "merkletreejs";
import { Component } from "react";
const keccak256 = require("keccak256");

var db = app.firestore();

class SetRoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Do not update the root in the smart contract unless necessary",
      root1: null,
    };
  }

  presale1root = () => {
    this.setState({
      finalRoot: "Please Wait",
    });

    presaleAddress()
      .then((data) => {
        var presale1 = data[0];

        console.log("array", presale1);

        // presale 1
        var leaves = presale1.map((v) => keccak256(v));
        var tree = new MerkleTree(leaves, keccak256, { sort: true });
        console.log(tree.getHexRoot());
        var rootOne = tree.getHexRoot();

        this.setState({
          finalRoot: rootOne,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          message: error,
        });
      });
  };

  render() {
    return (
      <div className="mt-20 flex flex-col items-center space-y-20 text-white">
        <div className="flex flex-col items-center space-y-3">
          <h1 className="text-2xl uppercase"> Adming Panel </h1>
          <div className="flex flex-col items-center space-y-3">
            <h3 className="text-xl"> Presale Root Code</h3>
            <button
              className="rounded-md bg-green px-4 py-2 text-xl"
              onClick={(e) => {
                e.preventDefault();
                this.presale1root();
              }}
            >
              Generate
            </button>
          </div>
        </div>

        <div className="space-y-2 text-center">
          <h2 className="text-xl">
            Generated Root Code: {this.state.finalRoot}
          </h2>
          <h2 className="text-xl">{this.state.message}</h2>
        </div>
      </div>
    );
  }
}

export default SetRoot;
