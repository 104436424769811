import React from "react";

import map from "../assets/img/map.png";
import lock from "../assets/img/lock.png";
import { Link } from "react-router-dom";

import worldmap1 from "../assets/img/worldmap-1.png";
import worldmap2 from "../assets/img/worldmap-2.png";

function Worldmap() {
  return (
    <section className="relative mt-20 px-8">
      <div className="py-10 ">
        <h2 className="title text-center text-3xl font-semibold uppercase">
          Worldmap
        </h2>
      </div>
      <div>
        <img
          className="absolute bottom-60 left-0 hidden lg:block"
          src={worldmap1}
          alt=""
        />
        <img
          className="index absolute top-10 right-0 hidden lg:block"
          src={worldmap2}
          alt=""
        />
        <figure className="mt-5 flex items-center justify-center">
          <img src={map} alt="" />
        </figure>
      </div>

      <div className="mx-auto mt-20 py-10 md:max-w-6xl">
        <div className="grid grid-cols-2 gap-6 md:grid-cols-4">
          <a href="/roadmap">
            <div className="vol flex h-36 flex-col items-center p-4 text-green">
              {/* <img className="absolute" src={lock} alt="" /> */}
              <h2 className="text-2xl font-bold">Vol. 1</h2>
              <p className="text-center text-xl">
                The Never Ending
                <br /> Fire
              </p>
            </div>
          </a>
          <div className="vol2 relative flex h-36 flex-col items-center p-4">
            <img className="absolute top-10 h-9" src={lock} alt="" />
            <h2 className="text-2xl font-bold">Vol. 2</h2>
            {/* <p className="text-center text-xl">Coming Soon</p> */}
          </div>
          <div className="vol2 relative flex h-36 flex-col items-center p-4">
            <img className="absolute top-10 h-9" src={lock} alt="" />
            <h2 className="text-2xl font-bold">Vol. 3</h2>
            {/* <p className="hidden text-center text-xl">Coming Soon</p> */}
          </div>
          <div className="vol2 relative flex h-36 flex-col items-center p-4">
            <img className="absolute top-10 h-9" src={lock} alt="" />
            <h2 className="text-2xl font-bold">Vol. 4</h2>
            {/* <p className="hidden text-center text-xl">Coming Soon</p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Worldmap;
