import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Home from "../pages/Home";
import Whitelist from "../Whitelist";
import GetWhitelist from "../walletWhitelist";
//

import Mint from "../Mint";
import SetRoot from "../RootGen";
import About from "../pages/About";
import Worldmap from "../pages/Worldmap";
import Roadmap from "../pages/Roadmap";
import Faq from "../pages/Faq";

function routes() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/about" exact>
          <About />
        </Route>
        <Route path="/worldmap" exact>
          <Worldmap />
        </Route>
        <Route path="/roadmap" exact>
          <Roadmap />
        </Route>
        <Route path="/faq" exact>
          <Faq />
        </Route>
        <Route path="/mint">
          <Mint />
        </Route>
        <Route path="/post-presale">
          <Whitelist />
        </Route>
        <Route path="/whitelist">
          <GetWhitelist />
        </Route>
        <Route path="/setroot">
          <SetRoot />
        </Route>
      </Switch>

      {/* <Footer /> */}
    </Router>
  );
}

export default routes;
