import React from "react";

import utility from "../assets/img/utility.png";
import vision from "../assets/img/vision.png";
import Community from "./Community";
import Team from "./Team";

function About() {
  return (
    <section className="mt-20 py-7">
      <div className="mx-auto w-full px-8 md:max-w-7xl">
        <h2 className="title text-center text-3xl font-semibold uppercase">
          About us
        </h2>
        <div className="space-y-4 md:space-y-7">
          {/* <p className="text-justify text-base md:text-xl">
            We’re thinking about the bigger picture.
          </p> */}

          <p className="text-justify text-base md:text-xl">
          The Last Koala Stronghold Vol.1 is an exciting collection of 933 generative art-style NFTs all made with love to the environment and koalas, drawn with ecofriendly pens n paper..
          </p>
        </div>
        <div className="flex flex-col-reverse py-20 md:flex-row md:space-x-16">
          <div className="mt-10 max-w-2xl md:mt-0">
            {/* <h2 className="title text-title font-semibold uppercase md:text-3xl">
            Vision
            </h2> */}

            <div className="mt-5 space-y-5 md:space-y-10">
              {/* <p className="text-justify text-base md:text-xl">
              👀👀👀
              </p>
              <p className="text-justify text-base md:text-xl">
                Alongside offering opportunities to buy our merchandise, the
                token will run the Koala economy, providing voting opportunities, access to raffle boxes, surprise giveaways and,
                of course, our game.
              </p>
              <p className="text-justify text-base md:text-xl">
                You’ll see other utility that is not tethered to our tokens,
                such as charitable donations and yet-to-be-revealed surprises.
              </p> */}
            </div>
          </div>
          {/* <figure>
            <img className="h-5/6" src={utility} alt="" />
          </figure> */}
        </div>

        <div className="flex flex-col space-y-10 md:flex-row md:space-x-16">
          <figure>
            <img className="" src={utility} alt="" />
          </figure>
          <div className=" max-w-2xl">
            <h2 className="title text-title font-semibold uppercase md:text-3xl">
              Vision
            </h2>
            <div className="mt-5 space-y-5 md:space-y-10">
              <p className="text-justify text-base md:text-xl">
              👀👀👀
              </p>
              {/* <p className="text-justify text-base md:text-xl">
                We want to provide a source of entertainment that will last and
                have the potential to return holder investment. We also harbor a
                desire to heal our planet by planting trees in the name of our
                collection.
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Community />
      {/* <Team /> */}
    </section>
  );
}

export default About;
