import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import app from "./components/fire.js";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";

var db = app.firestore();

function GetWhitelist() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState(`Click to add to whitelist`);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 4,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const storeAddress = async (e) => {
    e.preventDefault();
    var name = e.target.name.value;
    console.log("name: ", name);
    setFeedback("Sending address...");
    var found = false;

    await db
      .collection("presale")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((res) => {
          var data = res.data();
          if (data["address"].toString() == blockchain.account.toString()) {
            found = true;
          }
        });
      });

    if (found === true) {
      setFeedback("You're already in the list.");
      return;
    }

    await db.collection("presale").add({
      address: blockchain.account.toString(),
      name: name.toString(),
    });

    setFeedback("Successfully added to whitelist.");
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <div className="mint__page h-[100vh]  w-full px-8 py-10 md:px-40">
      <div
        className="w-full"
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/mint.png" : null}
      >
        <div className="mx-auto w-full py-28">
          <div className="mint__box flex h-full rounded-md bg-black py-10 shadow-lg">
            <div className="flex w-full items-center justify-center  px-6 py-6 md:space-x-10 md:px-10 md:py-8">
              <div className="">
                {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                  <></>
                ) : (
                  <>
                    {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                      <div className="flex w-full flex-col items-center justify-center">
                        <h3 className="font-primary text-center text-sm font-semibold text-white md:text-2xl md:tracking-wider">
                          Connect to the {CONFIG.NETWORK.NAME} Network
                        </h3>

                        <button
                          className="bg-purple font-primary mt-16 rounded-md bg-white px-10 py-4 font-bold tracking-wider text-black"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                          }}
                        >
                          CONNECT
                        </button>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <h2 class="font-Opensans mt-5 text-center font-semibold text-white">
                              {blockchain.errorMsg}
                            </h2>
                          </>
                        ) : null}
                      </div>
                    ) : (
                      <div className="flex w-full flex-col items-center justify-center space-y-4 md:space-y-8 ">
                        <form
                          onSubmit={storeAddress}
                          className="flex flex-col items-center space-y-6"
                        >
                          <label className="text-xl font-semibold text-white">
                            Name / Discord ID (optional)
                          </label>

                          <input
                            className="outline-none rounded-md py-2 px-4 text-black"
                            name="name"
                          />

                          <input
                            className="rounded-md px-3 py-2 text-base font-semibold text-black shadow-md"
                            type="submit"
                            value="Send Address"
                          />
                        </form>

                        <h2 className="font-primary text-base text-white md:text-2xl">
                          {feedback}
                        </h2>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetWhitelist;
