import React, { useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import img from "../assets/img/model2.png";
import app from "./fire";
// import app from "./components/fire.js";
import "firebase/compat/firestore";

import { toast } from "react-toastify";

var db = app.firestore();

function Modal({ showModal, onClose }) {
  const [input, setInput] = useState("");

  const sendPost = (e) => {
    e.preventDefault();
    if (input !== "") {
      db.collection("emails").add({
        email: input,
      });

      toast.success("Email Address Added", {
        position: "top-center",
      });
      setInput("");
    } else {
      toast.warning("Please add all feilds", {
        position: "top-center",
      });
    }
  };

  return (
    <section className="box absolute  z-50 rounded-md bg-black px-6  py-5 shadow-md">
      <div className="modal  p-5">
        <div className="absolute top-3 right-3 flex justify-end">
          <button onClick={onClose} className="text-2xl text-white">
            <RiCloseCircleFill />
          </button>
        </div>
        <div className="mt-4 flex space-x-4">
          <img
            className="hidden rounded-md md:inline-block md:h-36"
            src={img}
            alt="Image6"
          />
          <div className="flex flex-col items-center space-y-4">
            <div className="flex justify-between">
              <h2 className="font-Minecraft text-center text-base uppercase tracking-wider text-white md:text-xl">
                SUBSCRIBE NOW
              </h2>
            </div>
            <form className="flex flex-col items-center space-y-5">
              <input
                className="outline-none border-red bg-primary  w-full rounded-md border-2  py-2 px-2 text-sm text-black"
                type="email"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Email Address"
              />
              <button
                onClick={sendPost}
                type="submit"
                className="subscribe rounded-full border-2 border-green px-4 py-3 text-sm font-semibold  uppercase  text-green md:text-sm"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Modal;
