import React from "react";
import home from "../assets/img/home.png";
import logo from "../assets/img/logomobile.png";

function Home() {
  return (
    <section className="hero h-full">
      <div className="hero__container">
        <img src={home} className="hero hidden w-full md:inline-block" alt="" />
      </div>
      <div className="hero__mobile flex flex-col items-center justify-end ">
        <img className="md:hidden" src={logo} alt="" />
      </div>
    </section>
  );
}

export default Home;
