import React from "react";

function Community() {
  return (
    <section className="py-20">
      <div className="community mt-20 py-20">
        <div className="mx-auto flex max-w-5xl flex-col items-center space-y-7">
          <h2 className="title text-center text-3xl font-semibold uppercase">
            Community
          </h2>
          <div className="space-y-5">
            <p className="text-center text-base md:text-xl">
            We couldn’t provide all of this without the unwavering support of our community, and that’s why we place immense value on being a part of that. Joining that community is as simple as following us on Twitter, we hope u Will be a part of our journey in saving the extinction of the koalas likewise the tree environment.
            </p>
            {/* <p className="text-center text-base md:text-xl">
              We value our community so much, which is why we’re placing a lot
              of value on those that remain active. Community members can expect
              a great deal of utility, including raids, fan art, games, and
              community spirit.
            </p>
            <p className="text-center text-base md:text-xl">
              Be a part of each of our Volumes, and make your mark on the world
              using entertainment. Roadmap
            </p> */}
          </div>

          <a href="https://twitter.com/KoalaStronghold" className="rounded-md bg-green px-8 py-3 shadow-md">
          Follow Twitter
          </a>
        </div>
      </div>
    </section>
  );
}

export default Community;
